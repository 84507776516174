import Card from 'app/components/Card'
import QuestionHelper from 'app/components/QuestionHelper'
import Typography from 'app/components/Typography'
import { GET_TOKEN } from 'app/config/tokens'
import usePrice from 'app/hooks/sculptor/usePrice'
import useReserve from 'app/hooks/sculptor/useReserve'
import { useMultipleTotalSupply } from 'app/hooks/useTotalSupply'
import { useActiveWeb3React } from 'app/services/web3'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { currencyAmountToBigNumberJS, format } from 'lib/formatter'
import React from 'react'

import BuySculptBotton from '../dashboard/BuySculpButton'
import VestSculptBotton from '../dashboard/VestSculpButton'

const MarketsSummary = ({}) => {
  const { useAVarTokensPriceDollar } = usePrice()
  const { useAAndVarTokens, useReservesData } = useReserve()
  const reservesData = useReservesData()

  const aTokenAddresses: string[] = []
  const underlyingTokenAddresses: string[] = []
  for (let i = 0; i < reservesData.length; i++) {
    aTokenAddresses.push(reservesData[i].aTokenAddress)
    underlyingTokenAddresses.push(reservesData[i].underlyingTokenAddress)
  }
  const aTokens = useAAndVarTokens(aTokenAddresses)
  const aTokenTotalSupplys = useMultipleTotalSupply(aTokens)
  const aTokenPrices = useAVarTokensPriceDollar(underlyingTokenAddresses)

  let totalDepositedDollar = new BigNumberJS(0)
  for (let i = 0; i < reservesData.length; i++) {
    const totalSupply = aTokenTotalSupplys[reservesData[i].aTokenAddress]
    totalDepositedDollar = totalDepositedDollar.plus(currencyAmountToBigNumberJS(totalSupply).times(aTokenPrices[i]))
  }

  return (
    <Card className="w-full h-full border border-sculptor bg-dark-900/90 shadow-sculptor">
      <div className="flex flex-col justify-between gap-5 pb-2 md:flex-row">
        <div className="flex items-center justify-between w-full md:w-2/5">
          <div className="flex flex-col">
            <Typography variant="base">Total market size</Typography>
            <Typography variant="h1" weight={700} className="pt-4 text-white">
              $ {format(totalDepositedDollar)}
            </Typography>
          </div>
        </div>

        <div className="flex items-center justify-between w-full md:w-2/6">
          {/* <div className="flex flex-col">
            <Typography variant="base" className="flex">
              Total platform fees <QuestionHelper text={'50% of the platform fees go to token holders.'} />
            </Typography>
            <Typography variant="h1" weight={700} className="pt-4 text-white">
              $ -
            </Typography>
          </div> */}
        </div>

        <div className="flex items-center justify-center gap-2 md:flex-col lg:flex-row lg:justify-end md:w-3/6">
          <BuySculptBotton size="sm" />
          <VestSculptBotton size="sm" />
        </div>
      </div>
    </Card>
  )
}

export default MarketsSummary
