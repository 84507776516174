import { CurrencyAmount, Token } from '@sushiswap/core-sdk'
import Button from 'app/components/Button'
import { CurrencyLogo } from 'app/components/CurrencyLogo'
import Typography from 'app/components/Typography'
import { GET, GET_LOOPABLE } from 'app/config/sculptor/stake'
import { GET_TOKEN } from 'app/config/tokens'
import { GET_AVAR_TOKEN } from 'app/config/tokens/avar'
import { tryParseAmount } from 'app/functions'
import useLoop from 'app/hooks/sculptor/useLoop'
import usePrice from 'app/hooks/sculptor/usePrice'
import useReserve from 'app/hooks/sculptor/useReserve'
import useStakeToken from 'app/hooks/sculptor/useStakeToken'
import { useToken } from 'app/hooks/Tokens'
import { useTotalSupply } from 'app/hooks/useTotalSupply'
import { useActiveWeb3React } from 'app/services/web3'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { currencyAmountToBigNumberJS, format } from 'lib/formatter'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MAX_LEVERAGE } from '../loop/LoopBox'

interface MarketListItemProps {
  index: number
  underlyingTokenAddress: string
  rewardToken: Token
  aTokenAddress: string
  varTokenAddress: string
  depositApy: CurrencyAmount<Token>
  depositIncentiveApy: CurrencyAmount<Token>
  borrowApy: CurrencyAmount<Token>
  borrowIncentiveApy: CurrencyAmount<Token>
}

const MarketListItem = ({
  index,
  underlyingTokenAddress,
  rewardToken,
  aTokenAddress,
  varTokenAddress,
  depositApy,
  depositIncentiveApy,
  borrowApy,
  borrowIncentiveApy,
}: MarketListItemProps) => {
  const { chainId } = useActiveWeb3React()
  const AVAR_TOKENS = GET_AVAR_TOKEN(chainId)
  const { WRAPPED_NATIVE, SCULPT } = GET_TOKEN(chainId)
  const LOOPABLE_TOKEN_ADDRESS = GET_LOOPABLE(chainId)

  const router = useRouter()

  const allClaimableIncentiveRewardTokenAddress = [aTokenAddress, varTokenAddress]
  const { claim, useClaimableIncentiveRewards } = useStakeToken()
  const claimableIncentiveRewards = useClaimableIncentiveRewards(allClaimableIncentiveRewardTokenAddress)
  // let allClaimableIncentiveRewardsAmount = new BigNumberJS(0)
  // const allClaimableIncentiveRewardAddresses: Token[] = []
  // for (let i = 0; i < claimableIncentiveRewards.length; i++) {
  //   const amount = currencyAmountToBigNumberJS(claimableIncentiveRewards[i].amount)

  //   allClaimableIncentiveRewardsAmount = allClaimableIncentiveRewardsAmount.plus(amount)
  //   allClaimableIncentiveRewardAddresses.push(claimableIncentiveRewards[i].token)
  // }

  const [allClaimableIncentiveRewardsAmount, allClaimableIncentiveRewardAddresses] = useMemo(() => {
    return claimableIncentiveRewards.reduce(
      (acc, d) => {
        const amount = currencyAmountToBigNumberJS(d.amount)
        acc[0] = acc[0].plus(amount)
        acc[1].push(d.token)
        return acc
      },
      [new BigNumberJS(0), []] as [BigNumberJS, Token[]]
    )
  }, [claimableIncentiveRewards])

  const { useAVarTokenPriceDollar } = usePrice()
  const underlyingToken = AVAR_TOKENS[underlyingTokenAddress]

  const aToken = AVAR_TOKENS[aTokenAddress]
  const aTokenTotalSupply = useTotalSupply(aToken ?? undefined)
  const aTokenPrice = useAVarTokenPriceDollar(underlyingTokenAddress)

  const varToken = AVAR_TOKENS[varTokenAddress]
  const varTokenTotalSupply = useTotalSupply(varToken ?? undefined)
  const varTokenPrice = useAVarTokenPriceDollar(underlyingTokenAddress)

  const [buttonText, setButtonText] = useState('...')
  const [isClaiming, setIsClaiming] = useState<boolean>(false)
  useEffect(() => {
    if (isClaiming) {
      setButtonText('Vesting...')
    } else {
      setButtonText(`Vest ${format(allClaimableIncentiveRewardsAmount)} ${rewardToken?.symbol}`)
    }
  }, [isClaiming, allClaimableIncentiveRewardsAmount, rewardToken?.symbol])

  const handleVest = async () => {
    setIsClaiming(true)
    await claim(allClaimableIncentiveRewardAddresses)
    setIsClaiming(false)
  }

  const marketSize = currencyAmountToBigNumberJS(aTokenTotalSupply).times(aTokenPrice)
  const totalBorrowed = currencyAmountToBigNumberJS(varTokenTotalSupply).times(varTokenPrice)

  // Loop APR
  const { useUserAccountData, useUserReserveData, useReservesData } = useReserve()
  const reservesData = useReservesData()
  const reserve = useMemo(() => {
    return reservesData.find((d) => {
      const underlyingTokenLoop = AVAR_TOKENS[d.underlyingTokenAddress]
      return underlyingTokenLoop.address.toLowerCase() === underlyingToken?.address.toLowerCase()
    })
  }, [AVAR_TOKENS, reservesData, underlyingToken?.address])

  const amount: CurrencyAmount<Token> | undefined = useMemo(() => {
    return tryParseAmount('1', underlyingToken)
  }, [underlyingToken])

  const stakeAddresses = GET(chainId)
  const spender = stakeAddresses.loopAddress
  const { useCalculateCollateralBorrow } = useLoop(spender)
  const collateralBorrow = useCalculateCollateralBorrow(amount, MAX_LEVERAGE)
  const newCollateral = currencyAmountToBigNumberJS(collateralBorrow.newCollateral)
  const newBorrow = currencyAmountToBigNumberJS(collateralBorrow.newBorrow)

  const netApy: BigNumberJS = useMemo(() => {
    const depositApy = currencyAmountToBigNumberJS(reserve?.depositApy)
    const borrowApy = currencyAmountToBigNumberJS(reserve?.borrowApy)
    const amountIn = currencyAmountToBigNumberJS(amount)

    return depositApy.times(newCollateral).minus(borrowApy.times(newBorrow)).div(amountIn)
  }, [reserve?.depositApy, reserve?.borrowApy, amount, newCollateral, newBorrow])

  const rewardApy: BigNumberJS = useMemo(() => {
    const depositIncentiveApy = currencyAmountToBigNumberJS(reserve?.depositIncentiveApy)
    const borrowIncentiveApy = currencyAmountToBigNumberJS(reserve?.borrowIncentiveApy)
    const amountIn = currencyAmountToBigNumberJS(amount)
    // (IncentiveDepositAPR * newCollateralETH/amountIn) + (IncentiveBorrowAPR * newBorrowETH/amountIn)

    return depositIncentiveApy.times(newCollateral).plus(borrowIncentiveApy.times(newBorrow)).div(amountIn)
  }, [reserve?.depositIncentiveApy, reserve?.borrowIncentiveApy, amount, newCollateral, newBorrow])

  const loopApr: BigNumberJS = useMemo(() => {
    return rewardApy.minus(netApy)
  }, [netApy, rewardApy])

  const handleRedirect = useCallback(() => {
    router.push(`/markets/${underlyingToken?.symbol}-${underlyingToken?.address}`)
  }, [router, underlyingToken?.address, underlyingToken?.symbol])

  const loopableCurrencies = useMemo(() => {
    return reservesData.reduce((addresses, r) => {
      if (
        r.underlyingTokenAddress.toLowerCase() !== WRAPPED_NATIVE.address.toLowerCase() &&
        LOOPABLE_TOKEN_ADDRESS.includes(r.underlyingTokenAddress)
      ) {
        addresses.push(r.underlyingTokenAddress)
      }
      return addresses
    }, [] as string[])
  }, [reservesData, WRAPPED_NATIVE, LOOPABLE_TOKEN_ADDRESS])

  const isLoopable = useMemo(() => {
    const currencyIndex = loopableCurrencies.findIndex((c) => c.toLowerCase() === underlyingToken.address.toLowerCase())
    return currencyIndex !== -1
  }, [loopableCurrencies, underlyingToken.address])

  return (
    <div
      className={`flex flex-col lg:flex-row items-center gap-2 lg:gap-8 px-2 py-4 text-center hover:bg-dark-900/40 ${
        index !== 0 && 'border-t'
      } sm:p-8 border-dark-900`}
    >
      <Typography
        variant="base"
        weight={700}
        className="flex items-center justify-start w-full gap-2 mb-2 text-white lg:w-2/12 lg:mb-0 hover:cursor-pointer"
        onClick={handleRedirect}
      >
        <CurrencyLogo currency={underlyingToken ?? undefined} size={32} />
        {underlyingToken?.symbol}
      </Typography>

      <Typography
        variant="base"
        weight={700}
        className="flex justify-between w-full text-white lg:justify-center lg:w-1/12 hover:cursor-pointer"
        onClick={handleRedirect}
      >
        <span className="block font-normal text-secondary lg:hidden">Market size</span>
        <span>$ {format(marketSize, { average: true })}</span>
      </Typography>

      <Typography
        variant="base"
        weight={700}
        className="flex justify-between w-full text-white lg:justify-center lg:w-1/12 hover:cursor-pointer"
        onClick={handleRedirect}
      >
        <span className="block font-normal text-secondary lg:hidden">Total borrowed</span>
        <span>$ {format(totalBorrowed, { average: true })}</span>
      </Typography>

      <Typography
        variant="base"
        weight={700}
        className="flex justify-between w-full text-white lg:justify-center lg:w-2/12 hover:cursor-pointer"
        onClick={handleRedirect}
      >
        <span className="block font-normal text-secondary lg:hidden">Deposit APY</span>
        <div className="flex flex-col gap-1 text-right lg:text-center">
          {format(currencyAmountToBigNumberJS(depositApy), { average: true })} %
          <Typography
            variant="xs"
            className="flex items-center justify-center gap-1 px-2 py-1 mt-0 border rounded lg:px-4 lg:mt-1 w-fit border-dark-900"
          >
            <CurrencyLogo currency={rewardToken ?? undefined} size={14} />
            {format(currencyAmountToBigNumberJS(depositIncentiveApy), { average: true })} %
            <span className="text-secondary">APR</span>
          </Typography>
        </div>
      </Typography>

      <Typography
        variant="base"
        weight={700}
        className="flex justify-between w-full text-white lg:justify-center lg:w-2/12 hover:cursor-pointer"
        onClick={handleRedirect}
      >
        <span className="block font-normal text-secondary lg:hidden">Borrow APY</span>
        <div className="flex flex-col gap-1 text-right lg:text-center">
          {format(currencyAmountToBigNumberJS(borrowApy), { average: true })} %
          <Typography
            variant="xs"
            className="flex items-center justify-center gap-1 px-2 py-1 mt-0 border rounded lg:px-4 lg:mt-1 w-fit border-dark-900"
          >
            <CurrencyLogo currency={rewardToken ?? undefined} size={14} />
            {format(currencyAmountToBigNumberJS(borrowIncentiveApy), { average: true })} %
            <span className="text-secondary">APR</span>
          </Typography>
        </div>
      </Typography>

      <Typography variant="base" weight={700} className="flex justify-between w-full lg:w-2/12">
        {isLoopable ? (
          <span className="flex items-center justify-between w-full gap-1 text-white lg:justify-center">
            <span className="block font-normal text-secondary lg:hidden">Loop APR</span>
            <div className="flex flex-col items-center gap-1">
              <Typography className="flex items-center justify-center gap-1 px-0 py-1 lg:px-4 lg:justify-end">
                <CurrencyLogo currency={rewardToken ?? undefined} size={14} />
                {format(loopApr, { average: true })} %<span className="text-secondary">APR</span>
              </Typography>
              <Button
                size="xs"
                className="w-24 px-8 last:whitespace-nowrap"
                onClick={() => router.push(`/loop/${underlyingToken?.symbol}-${underlyingToken?.address}`)}
              >
                Loop
              </Button>
            </div>
          </span>
        ) : (
          <span className="flex items-center justify-between w-full gap-1 text-white lg:justify-center">
            <span className="block font-normal text-secondary lg:hidden">Loop APR</span>
            <div className="flex flex-col gap-1 text-right lg:text-center">-</div>
          </span>
        )}
      </Typography>

      {/* <div className="flex w-full lg:w-5/12">
        <Typography
          variant="base"
          weight={700}
          className="flex flex-col items-start justify-center w-full gap-1 my-4 text-white lg:gap-0 lg:items-center lg:w-1/2 lg:my-0 hover:cursor-pointer"
          onClick={handleRedirect}
        >
          <span className="block font-normal text-secondary lg:hidden">Deposit APY</span>
          {format(currencyAmountToBigNumberJS(depositApy))} %
          <Typography
            variant="xs"
            className="flex items-center justify-center gap-1 px-4 py-1 mt-1 border rounded w-fit border-dark-900"
          >
            <CurrencyLogo currency={rewardToken ?? undefined} size={14} />
            {format(currencyAmountToBigNumberJS(depositIncentiveApy))} %<span className="text-secondary">APR</span>
          </Typography>
        </Typography>

        <Typography
          variant="base"
          weight={700}
          className="flex flex-col items-end justify-center w-full gap-1 my-4 text-white lg:gap-0 lg:items-center lg:w-1/2 lg:my-0 hover:cursor-pointer"
          onClick={handleRedirect}
        >
          <span className="block font-normal text-secondary lg:hidden">Borrow APY</span>
          {format(currencyAmountToBigNumberJS(borrowApy), { average: true })} %
          <Typography
            variant="xs"
            className="flex items-center gap-1 px-4 py-1 mt-1 border rounded w-fit border-dark-900"
          >
            <CurrencyLogo currency={rewardToken ?? undefined} size={14} />
            {format(currencyAmountToBigNumberJS(borrowIncentiveApy), { average: true })} %
            <span className="text-secondary">APR</span>
          </Typography>
        </Typography>

        {isLoopable ? (
          <Typography
            variant="base"
            weight={700}
            className="flex flex-col items-end justify-center w-full gap-1 my-4 text-white lg:gap-0 lg:items-center lg:w-1/2 lg:my-0"
          >
            <span className="block font-normal text-secondary lg:hidden">Loop APR</span>
            <Typography className="flex items-center gap-1 px-4 py-1">
              <CurrencyLogo currency={rewardToken ?? undefined} size={14} />
              {format(loopApr, { average: true })} %<span className="text-secondary">APR</span>
            </Typography>
            <Button
              size="xs"
              className="px-8 last:whitespace-nowrap"
              onClick={() => router.push(`/loop/${underlyingToken?.symbol}-${underlyingToken?.address}`)}
            >
              Loop
            </Button>
          </Typography>
        ) : (
          <Typography
            variant="base"
            weight={700}
            className="flex flex-col items-end justify-center w-full gap-1 my-4 text-white lg:gap-0 lg:items-center lg:w-1/2 lg:my-0"
          >
            -
          </Typography>
        )}
      </div> */}
      <Button
        size="sm"
        disabled={isClaiming || allClaimableIncentiveRewardsAmount.eq(0)}
        className="w-full lg:w-2/12 whitespace-nowrap"
        onClick={handleVest}
      >
        {buttonText}
      </Button>
    </div>
  )
}

export default MarketListItem
