import { Token } from '@sushiswap/core-sdk'
import LoadingText from 'app/components/LoadingText'
import Typography from 'app/components/Typography'
import { RESERVES_NUMBER } from 'app/config/sculptor/skeleton'
import { GET_TOKEN } from 'app/config/tokens'
import { GET_AVAR_TOKEN } from 'app/config/tokens/avar'
import useReserve from 'app/hooks/sculptor/useReserve'
import { useActiveWeb3React } from 'app/services/web3'
import _ from 'lodash'
import React from 'react'

import MarketListItem from './MarketListItem'
import MarketListItemSkeleton from './MarketListItemSkeleton'

export enum AssetTypes {
  DEFAULT,
  STABLECOIN,
}

export interface MarketListItemData {
  token: Token
  marketSize: string
  totalBorrowed: string
  depositAPY: string
  depositAPR: string
  borrowAPY: string
  borrowAPR: string
  vestable: string
  availableBorrow: string
  availableBorrowsETH: string
  price: number
}

const MarketList = () => {
  const { chainId } = useActiveWeb3React()
  const rewardToken = GET_TOKEN(chainId).SCULPT

  const { useReservesData } = useReserve()
  const reservesData = useReservesData()

  return (
    <>
      <div className="hidden gap-6 px-4 mt-8 text-center sm:gap-8 lg:flex sm:px-8">
        <Typography variant="base" className="w-2/12 text-left">
          Assets
        </Typography>
        <Typography variant="base" className="w-1/12">
          Market size
        </Typography>
        <Typography variant="base" className="w-1/12">
          Total borrowed
        </Typography>
        <Typography variant="base" className="w-2/12">
          Deposit APY
        </Typography>
        <Typography variant="base" className="w-2/12">
          Borrow APY
        </Typography>
        <Typography variant="base" className="w-2/12">
          Loop APR
        </Typography>
        <div className="w-2/12" />
      </div>

      <div className="w-full h-full border rounded border-dark-900">
        {reservesData.length > 0
          ? reservesData.map((d, i) => {
              return (
                <MarketListItem
                  key={i}
                  index={i}
                  underlyingTokenAddress={d.underlyingTokenAddress}
                  rewardToken={rewardToken}
                  aTokenAddress={d.aTokenAddress}
                  varTokenAddress={d.variableDebtTokenAddress}
                  depositApy={d.depositApy}
                  depositIncentiveApy={d.depositIncentiveApy}
                  borrowApy={d.borrowApy}
                  borrowIncentiveApy={d.borrowIncentiveApy}
                />
              )
            })
          : _.times(RESERVES_NUMBER, (i) => <MarketListItemSkeleton key={i} index={i} />)}
      </div>
    </>
  )
}

export default MarketList
