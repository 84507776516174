import Card from 'app/components/Card'
import Typography from 'app/components/Typography'
import { GET_TOKEN } from 'app/config/tokens'
import usePrice from 'app/hooks/sculptor/usePrice'
import useStakeToken from 'app/hooks/sculptor/useStakeToken'
import { useTotalSupply } from 'app/hooks/useTotalSupply'
import { useActiveWeb3React } from 'app/services/web3'
import { useTokenBalance } from 'app/state/wallet/hooks'
import { currencyAmountToBigNumberJS, format } from 'lib/formatter'
import React from 'react'

const SculptBar = ({}) => {
  const { chainId, account } = useActiveWeb3React()
  const { SCULPT, SCULPTETH } = GET_TOKEN(chainId)

  const { useGovTokenPriceDollar } = usePrice()
  const tokenPrice = useGovTokenPriceDollar(SCULPTETH)

  const { useTotalLockedAmount, useTotalStakedAmount } = useStakeToken()

  const totalLockedAmountRaw = useTotalLockedAmount(SCULPT)
  const totalLockedAmount = currencyAmountToBigNumberJS(totalLockedAmountRaw)
  const totalLockedAmountDollar = totalLockedAmount.times(tokenPrice)

  const totalStakedAmountRaw = useTotalStakedAmount(SCULPT)
  const totalStakedAmount = currencyAmountToBigNumberJS(totalStakedAmountRaw)
  const totalStakedAmountDollar = totalStakedAmount.times(tokenPrice)

  const tokenBurnedRaw = useTokenBalance('0x000000000000000000000000000000000000dEaD', SCULPT)
  const tokenBurned = currencyAmountToBigNumberJS(tokenBurnedRaw)
  const tokenTotalSupplyRaw = useTotalSupply(SCULPT)
  const tokenTotalSupply = currencyAmountToBigNumberJS(tokenTotalSupplyRaw).minus(tokenBurned)
  const totalLockedAmountPercent = totalLockedAmount.div(tokenTotalSupply).times(100)

  const marketCap = tokenTotalSupply.times(tokenPrice)

  return (
    <div className="flex flex-col gap-6 sm:gap-8 md:flex-row">
      {account && (
        <Card className="w-full h-full border md:w-2/5 border-sculptor bg-dark-900/90 shadow-sculptor">
          <div className="flex flex-col justify-between gap-6 sm:gap-8 xl:flex-row">
            <div className="flex flex-col w-full xl:w-1/2">
              <Typography variant="base">{SCULPT.symbol} Locked</Typography>
              <Typography variant="h1" weight={700} className="pt-4 text-white">
                {format(totalLockedAmount, { average: true })}
              </Typography>
              <Typography variant="base" className="pt-4">
                $ {format(totalLockedAmountDollar)}
              </Typography>
            </div>

            <div className="flex flex-col w-full xl:w-1/2">
              <Typography variant="base">{SCULPT.symbol} Staked</Typography>
              <Typography variant="h1" weight={700} className="pt-4 text-white">
                {format(totalStakedAmount, { average: true })}
              </Typography>
              <Typography variant="base" className="pt-4">
                $ {format(totalStakedAmountDollar)}
              </Typography>
            </div>
          </div>
        </Card>
      )}

      <Card
        className={`w-full h-full border border-sculptor bg-dark-900/90 shadow-sculptor ${
          account ? 'md:w-3/5' : 'md:w-5/5'
        }`}
      >
        <div className="flex flex-col justify-between gap-5 xl:flex-row">
          <div className="flex flex-col w-full xl:w-1/3">
            <Typography variant="base">{SCULPT.symbol} Price</Typography>
            <Typography variant="h1" weight={700} className="pt-4 text-white">
              $ {format(tokenPrice, { mantissa: 6 })}
            </Typography>
          </div>

          <div className="flex flex-col w-full xl:w-1/3">
            <Typography variant="base">Circulating supply</Typography>
            <Typography variant="h1" weight={700} className="pt-4 text-white">
              {format(tokenTotalSupply, { average: true })}
            </Typography>
            <Typography variant="base" className="pt-4">
              {format(totalLockedAmountPercent)} % LOCKED
            </Typography>
          </div>

          <div className="flex flex-col w-full xl:w-1/3">
            <Typography variant="base">Market Cap</Typography>
            <Typography variant="h1" weight={700} className="pt-4 text-white">
              $ {format(marketCap, { average: true })}
            </Typography>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default SculptBar
