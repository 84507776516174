import Button from 'app/components/Button'
import Container from 'app/components/Container'
import ExternalLink from 'app/components/ExternalLink'
import { GET } from 'app/config/sculptor/stake'
import { GET_TOKEN } from 'app/config/tokens'
import HeaderStat from 'app/features/markets/HeaderStat'
import MarketList from 'app/features/markets/MarketList'
import MarketsSummary from 'app/features/markets/MarketsSummary'
import SculptBar from 'app/features/markets/SculptBar'
import { useActiveWeb3React } from 'app/services/web3'
import Head from 'next/head'
import React from 'react'

export default function Markets() {
  const { chainId, account } = useActiveWeb3React()
  const { SCULPT, SCULPTETH } = GET_TOKEN(chainId)

  const stakeAddresses = GET(chainId)
  const { swapUrl } = stakeAddresses

  return (
    <>
      <Head>
        <title>Markets | Sculptor</title>
        <meta key="description" name="description" content="Sculptor markets..." />
        <meta key="twitter:description" name="twitter:description" content="Sculptor markets..." />
        <meta key="og:description" property="og:description" content="Sculptor markets..." />
      </Head>

      <div className="flex flex-col items-center w-full">
        <div className="flex flex-col items-center justify-center w-full pt-20 transition duration-500 bg-fixed bg-center bg-no-repeat bg-cover h-[83rem] lg:h-[66rem] xl:h-[53rem] md:h-[74rem] bg-eclipse">
          <div className="flex flex-col w-full px-5 lg:flex-row lg:px-6 max-w-7xl gap-x-8">
            <div className="flex flex-col w-full pt-2 lg:w-4/6 md:pt-16 lg:pt-32">
              <p className="text-4xl sm:text-5xl drop-shadow lg:text-6xl">LEND AND BORROW WHILE EARNING FEES</p>
              <p className="mt-2 md:mt-4 lg:mt-4 text-md drop-shadow sm:text-xl lg:text-2xl">
                Sculpt the perfect portfolio and watch your tokens grow.
              </p>
              <div className="flex flex-row flex-1 gap-4 mt-8 md:mt-10 lg:mt-10">
                <ExternalLink href={`https://sculptor-finance.gitbook.io/`}>
                  <Button
                    variant="outlined"
                    size="sm"
                    color="white"
                    className="drop-shadow text-sm md:text-md px-6 h-[36px] md:px-8 md:h-[52px]"
                  >
                    More Details
                  </Button>
                </ExternalLink>

                <ExternalLink href={`${swapUrl}&outputCurrency=${SCULPT.address}`}>
                  <Button
                    variant="filled"
                    size="sm"
                    color="white"
                    className=" !text-dark-1000 drop-shadow text-sm md:text-md px-6 h-[36px] md:px-8 md:h-[52px]"
                  >
                    Buy {SCULPT.symbol}
                  </Button>
                </ExternalLink>
              </div>
            </div>
            <div className="flex flex-col w-full mt-16 lg:w-2/6 lg:mt-0">
              <HeaderStat />
            </div>
          </div>
          <div className="flex flex-row w-full h-40 mt-5 bg-gradient-to-t from-dark-1000 to-dark-1000/0"></div>
        </div>

        <Container className="flex flex-col gap-6 px-5 pb-10 -mt-10 sm:gap-8 lg:px-6" maxWidth="7xl">
          <SculptBar />
          <MarketsSummary />
          <MarketList />
        </Container>
      </div>
    </>
  )
}
