import Card from 'app/components/Card'
import QuestionHelper from 'app/components/QuestionHelper'
import Typography from 'app/components/Typography'
import { GET_TOKEN } from 'app/config/tokens'
import usePrice from 'app/hooks/sculptor/usePrice'
import useStakeToken from 'app/hooks/sculptor/useStakeToken'
import { useTotalSupply } from 'app/hooks/useTotalSupply'
import { useActiveWeb3React } from 'app/services/web3'
import { currencyAmountToBigNumberJS, format } from 'lib/formatter'
import React, { useEffect, useMemo, useState } from 'react'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import HeaderStatItem from './HeaderStatItem'
import useReserve from 'app/hooks/sculptor/useReserve'
import { classNames } from 'app/functions'
import Button from 'app/components/Button'
import { useRouter } from 'next/router'

const HeaderStat = ({}) => {
  const { chainId, account } = useActiveWeb3React()
  const { SCULPT, SCULPTETH } = GET_TOKEN(chainId)

  const router = useRouter()

  const [fee, setFee] = useState()

  const { useReservesData } = useReserve()
  const reservesData = useReservesData()

  const { useGovTokenPriceDollar, useLPPriceDollar } = usePrice()
  const tokenPrice = useGovTokenPriceDollar(SCULPTETH)
  // const lpPrice = useLPPriceDollar(SCULPTETH)

  const { useAllRewardData, useTotalLockedAmount, useTotalStakedAmount } = useStakeToken()
  const allRewardData = useAllRewardData(SCULPT)

  const totalLockedAmountRaw = useTotalLockedAmount(SCULPT)
  const totalLockedAmount = currencyAmountToBigNumberJS(totalLockedAmountRaw)
  const totalLockedAmountDollar = totalLockedAmount.times(tokenPrice)

  const totalStakedAmountRaw = useTotalStakedAmount(SCULPT)
  const totalStakedAmount = currencyAmountToBigNumberJS(totalStakedAmountRaw)
  const totalStakedAmountDollar = totalStakedAmount.times(tokenPrice)

  // const tokenTotalSupplyRaw = useTotalSupply(SCULPT)
  // const tokenTotalSupply = currencyAmountToBigNumberJS(tokenTotalSupplyRaw)

  // const totalLockedAmountPercent = totalLockedAmount.div(tokenTotalSupply).times(100)

  // const marketCap = tokenTotalSupply.times(tokenPrice)

  const apr = useMemo(() => {
    const [allAtokenRewardRateDollar, tokenRewardRateDollar] = allRewardData.reduce(
      (sum, d, i) => {
        if (d.token.address.toLowerCase() === SCULPT.address.toLowerCase()) {
          sum[1] = sum[1].plus(currencyAmountToBigNumberJS(d.rewardRate).times(tokenPrice))
        } else {
          sum[0] = sum[0].plus(currencyAmountToBigNumberJS(d.rewardRate).times(d.aTokenPrice ?? 0))
        }
        return sum
      },
      [new BigNumberJS(0), new BigNumberJS(0)]
    )

    return allAtokenRewardRateDollar
      .div(totalStakedAmountDollar)
      .plus(tokenRewardRateDollar.div(totalLockedAmountDollar))
      .times(86400 * 365)
      .times(100)
  }, [allRewardData, totalStakedAmountDollar, totalLockedAmountDollar, SCULPT, tokenPrice])

  useEffect(() => {
    const fetchFee = async () => {
      try {
        // Load messages from AWS, use q session param to get latest version from cache
        const res = await fetch(`https://api.sculptor.fi/v1/reward/date`)
        const { total_fee_in_usd } = await res.json()

        setFee(total_fee_in_usd)
      } catch (e) {
        console.log(e)
      }
    }
    fetchFee()

    const id = setInterval(fetchFee, 10 * 1000)

    return () => {
      clearInterval(id)
    }
  }, [setFee])

  return (
    <div className="flex flex-col w-full gap-6 sm:gap-8">
      <Card className="w-full h-full border border-sculptor bg-dark-900/90 shadow-sculptor">
        <div className="flex flex-col justify-between gap-6 sm:gap-8 xl:flex-row">
          <div className="flex flex-col w-full text-center">
            <Typography>
              <span className="flex justify-center">
                Total platform fees <QuestionHelper text={'50% of the platform fees go to token holders.'} />
              </span>
            </Typography>
            <Typography variant="h1" weight={700} className="pt-4 text-white">
              $ {fee === undefined ? '-' : format(fee)}
            </Typography>
            <Typography variant="xs" className="pt-8">
              Platform Fees such as interest, early exit penalties and liquidations are distributed to {SCULPT.symbol}{' '}
              Lockers.
            </Typography>
          </div>
        </div>
      </Card>

      <Card className="w-full h-full border border-sculptor bg-dark-900/90 shadow-sculptor">
        <div className="flex flex-col justify-between gap-6 sm:gap-8 xl:flex-row">
          <div className="flex flex-col w-full">
            <Typography variant="base" className="text-center">
              <span className="font-bold">Lock</span> <span className="font-bold text-sculptor">{SCULPT.symbol}</span>{' '}
              and <span className="font-bold">Earn</span>{' '}
              <span className="font-bold text-sculptor">{format(apr)}% APR</span> paid in
            </Typography>
            <div className="flex flex-row flex-wrap mt-4">
              {/*
              {allRewardData.map((d, i) => {
                if (reservesData.length > 0) {
                const underlyingTokenReserve = reservesData.find(
                  (r) => r.aTokenAddress.toLowerCase() === d.token.address.toLowerCase()
                )
                const isAIsSculpt = d.token.address.toLowerCase() === SCULPT.address.toLowerCase()
                if (underlyingTokenReserve || isAIsSculpt) {
                  return (
                    <span className={classNames(i === 0 ? 'w-full' : 'w-full md:w-1/2 lg:w-full xl:w-1/2', 'p-1')}>
                      <HeaderStatItem
                        key={i}
                        underlyingTokenAddress={underlyingTokenReserve?.underlyingTokenAddress ?? SCULPT.address}
                        rewardRate={d.rewardRate}
                        rewardPrice={isAIsSculpt ? tokenPrice : d.aTokenPrice ?? 0}
                        totalDollar={isAIsSculpt ? totalLockedAmountDollar : totalStakedAmountDollar ?? 0}
                      />
                    </span>
                  )
                }
                }
              })} */}

              {[{ underlyingTokenAddress: undefined, aTokenAddress: SCULPT.address }, ...reservesData].map((r, i) => {
                const data = allRewardData.find((d) => r.aTokenAddress?.toLowerCase() === d.token.address.toLowerCase())
                const isAIsSculpt = r.aTokenAddress?.toLowerCase() === SCULPT.address.toLowerCase()
                return (
                  <span
                    key={i}
                    className={classNames(i === 0 ? 'w-full' : 'w-full md:w-1/2 lg:w-full xl:w-1/2', 'p-1')}
                  >
                    <HeaderStatItem
                      underlyingTokenAddress={r.underlyingTokenAddress ?? SCULPT.address}
                      rewardRate={data?.rewardRate}
                      rewardPrice={isAIsSculpt ? tokenPrice : data?.aTokenPrice ?? 0}
                      totalDollar={isAIsSculpt ? totalLockedAmountDollar : totalStakedAmountDollar ?? 0}
                    />
                  </span>
                )
              })}
            </div>
            <div className="px-1 mt-4">
              <Button fullWidth size="sm" variant="filled" onClick={() => router.push(`/sculpt`)}>
                Lock {SCULPT.symbol}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default HeaderStat
