import { CurrencyAmount, Token } from '@sushiswap/core-sdk'
import Button from 'app/components/Button'
import { CurrencyLogo } from 'app/components/CurrencyLogo'
import Typography from 'app/components/Typography'
import { GET_AVAR_TOKEN } from 'app/config/tokens/avar'
import { classNames } from 'app/functions'
import usePrice from 'app/hooks/sculptor/usePrice'
import useStakeToken from 'app/hooks/sculptor/useStakeToken'
import { useToken } from 'app/hooks/Tokens'
import { useTotalSupply } from 'app/hooks/useTotalSupply'
import { useActiveWeb3React } from 'app/services/web3'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { currencyAmountToBigNumberJS, format } from 'lib/formatter'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'

interface MarketListItemSkeletonProps {
  index: number
}

const MarketListItemSkeleton = ({ index }: MarketListItemSkeletonProps) => {
  return (
    <div
      className={classNames(
        'animate-pulse bg-dark-900 transition-all',
        'flex flex-col lg:flex-row items-center gap-2 lg:gap-5 px-2 py-4 text-center hover:bg-dark-800/40 sm:px-8 sm:py-4 border-dark-800',
        index !== 0 && 'border-t'
      )}
    >
      <div className="flex items-center w-full gap-2 mb-2 text-left text-white lg:w-1/6 lg:mb-0">
        <div className="w-[32px] h-[32px] rounded-full bg-dark-800" />
        <div className="h-4 rounded w-28 bg-dark-800" />
      </div>

      <div className="flex justify-between w-full text-white lg:justify-center lg:w-1/6">
        <span className="block font-normal text-secondary lg:hidden">Market size</span>
        <div className="h-4 rounded w-28 bg-dark-800" />
      </div>

      <div className="flex justify-between w-full text-white lg:justify-center lg:w-1/6">
        <span className="block font-normal text-secondary lg:hidden">Total borrowed</span>
        <div className="h-4 rounded w-28 bg-dark-800" />
      </div>

      <div className="flex w-full lg:w-2/6">
        <div className="flex flex-col items-start w-full gap-1 my-4 text-white lg:gap-0 lg:items-center lg:w-1/2 lg:my-0">
          <span className="block font-normal text-secondary lg:hidden">Deposit APY</span>
          <span className="flex flex-col gap-1">
            <div className="w-40 h-4 rounded bg-dark-800" />
            <div className="w-40 h-4 rounded bg-dark-800" />
          </span>
        </div>

        <div className="flex flex-col items-end w-full gap-1 my-4 text-white lg:gap-0 lg:items-center lg:w-1/2 lg:my-0">
          <span className="block font-normal text-secondary lg:hidden">Borrow APY</span>
          <span className="flex flex-col gap-1">
            <div className="w-40 h-4 rounded bg-dark-800" />
            <div className="w-40 h-4 rounded bg-dark-800" />
          </span>
        </div>
      </div>
      <div className="w-full lg:w-1/6 whitespace-nowrap"></div>
    </div>
  )
}

export default MarketListItemSkeleton
