import { CurrencyAmount, Token } from '@sushiswap/core-sdk'
import Button from 'app/components/Button'
import { CurrencyLogo } from 'app/components/CurrencyLogo'
import Typography from 'app/components/Typography'
import { GET } from 'app/config/sculptor/stake'
import { GET_TOKEN } from 'app/config/tokens'
import { GET_AVAR_TOKEN } from 'app/config/tokens/avar'
import { tryParseAmount } from 'app/functions'
import useLoop from 'app/hooks/sculptor/useLoop'
import usePrice from 'app/hooks/sculptor/usePrice'
import useReserve from 'app/hooks/sculptor/useReserve'
import useStakeToken from 'app/hooks/sculptor/useStakeToken'
import { useToken } from 'app/hooks/Tokens'
import { useTotalSupply } from 'app/hooks/useTotalSupply'
import { useActiveWeb3React } from 'app/services/web3'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { currencyAmountToBigNumberJS, format } from 'lib/formatter'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MAX_LEVERAGE } from '../loop/LoopBox'

interface HeaderStatItemProps {
  underlyingTokenAddress: string
  rewardRate: CurrencyAmount<Token> | undefined
  rewardPrice: number
  totalDollar: BigNumberJS
}

const HeaderStatItem = ({ underlyingTokenAddress, rewardRate, rewardPrice, totalDollar }: HeaderStatItemProps) => {
  const { chainId } = useActiveWeb3React()
  const AVAR_TOKENS = GET_AVAR_TOKEN(chainId)
  const underlyingToken = AVAR_TOKENS[underlyingTokenAddress]

  const apr = useMemo(() => {
    return currencyAmountToBigNumberJS(rewardRate)
      .times(rewardPrice)
      .div(totalDollar)
      .times(86400 * 365)
      .times(100)
  }, [rewardRate, rewardPrice, totalDollar])

  return (
    <div
      className={
        'flex flex-row w-full lg:flex-row items-center p-2 text-center justify-between border rounded border-dark-700'
      }
    >
      <Typography variant="base" weight={700} className="flex items-center justify-start w-full gap-2 lg:w-2/12">
        <CurrencyLogo currency={underlyingToken ?? undefined} size={32} />
        {underlyingToken?.symbol}
      </Typography>

      <Typography variant="base" weight={700} className="flex flex-row text-white gap-x-2">
        <span>{format(apr, { mantissa: apr.gte(1000) ? 0 : 2 })}</span>
        <span>%</span>
      </Typography>
    </div>
  )
}

export default HeaderStatItem
